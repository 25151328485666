
import { Options, Vue, prop } from "vue-class-component";
import VueApexCharts from "vue3-apexcharts";

class Props {
  seriesTitle = prop<string>({
    default: "Total",
    type: String
  });
  seriesData = prop<any[]>({
    default: [],
    type: Array
  });
  groupedSeriesData = prop<any[]>({
    default: [],
    type: Array
  });
  groupedColor = prop<any[]>({
    default: [],
    type: Array
  });
  height = prop<number>({
    default: 350,
    type: Number
  });
  categories = prop<any[]>({
    default: [],
    type: Array
  });
  colorBar = prop<string>({
    default: "#7ACB90",
    type: String
  });
  colorLabel = prop<string>({
    default: "#78787A",
    type: String
  });
  tooltip = prop<boolean>({
    default: false,
    type: Boolean
  });
  rounded = prop<boolean>({
    default: true,
    type: Boolean
  });
  legend = prop<boolean>({
    default: false,
    type: Boolean
  });
  barLabel = prop<boolean>({
    default: false,
    type: Boolean
  });
  barColumnWidth = prop<string>({
    default: "20%",
    type: String
  });
  xAxisFontSize = prop<string>({
    default: "8px",
    type: String
  });
  yAxisFontSize = prop<string>({
    default: "8px",
    type: String
  });
}

@Options({
  components: {
    VueApexCharts
  }
})
export default class ColumnBar extends Vue.with(Props) {
  series = [
    {
      name: this.seriesTitle,
      data: this.seriesData
    }
  ];
  get seriesChartData() {
    if (this.groupedSeriesData.length) {
      return this.groupedSeriesData;
    }
    return this.series;
  }
  chartOptions = {
    chart: {
      fontFamily: "Poppins",
      type: "bar",
      height: this.height,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: this.barColumnWidth,
        endingShape: this.rounded ? "rounded" : "",
        borderRadius: this.rounded ? 8 : 0,
        dataLabels: {
          position: "top"
        }
      }
    },
    dataLabels: {
      enabled: this.barLabel,
      offsetX: 0,
      offsetY: -20,
      style: {
        fontSize: "10px",
        fontWeight: 400,
        colors: ["#4d4d4d"]
      }
    },
    legend: {
      show: this.legend,
      fontSize: 14,
      offsetY: 5,
      markers: {
        width: 10,
        height: 10,
        radius: 20,
        shape: "square",
        size: 8
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0
      }
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
      fill: {
        type: "solid",
        opacity: 0.85,
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: []
        }
      }
    },
    xaxis: {
      categories: this.categories,
      labels: {
        rotate: -65,
        style: {
          colors: this.seriesData.map(() => this.colorLabel),
          fontSize: this.xAxisFontSize,
          fontFamily: "Poppins",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-label"
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: [this.colorLabel],
          fontSize: this.yAxisFontSize,
          fontFamily: "Poppins",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label"
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0
      }
    },
    colors: Array.from(
      { length: this.series.length },
      () => this.groupedColor
    ).flat(),
    fill: {
      colors: this.groupedColor.length
        ? Array.from(
            { length: this.series.length },
            () => this.groupedColor
          ).flat()
        : [this.colorBar]
    },
    grid: {
      show: true,
      borderColor: "#E0E0E0",
      strokeDashArray: 5,
      position: "back",
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    states: {
      hover: {
        filter: {
          type: "none"
        }
      }
    },
    tooltip: {
      enabled: this.tooltip,
      y: {
        formatter: (val: number) => val.toFixed()
      }
    }
  };
}
