import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueApexCharts = _resolveComponent("VueApexCharts")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_VueApexCharts, {
      type: "bar",
      height: _ctx.height,
      options: _ctx.chartOptions,
      series: _ctx.seriesChartData
    }, null, 8, ["height", "options", "series"])
  ]))
}